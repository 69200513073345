import * as Sentry from '@sentry/react';

export const setSentryContext = ({ user, company = null }) => {
  if (user) {
    const { email, companyAccess, name } = user;
    Sentry.setUser({ username: name, email, companyAccess });

    if (company) {
      Sentry.setTag('company.id', company?.id);
      Sentry.setContext('company', company);
    }
  }
};

export default setSentryContext;
