import { Box } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import { REDIRECT_LOGOUT_KEY } from 'constants/string-keys';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import * as ROUTES from 'routes/routes';
import { isLoggiDomain } from 'service/migration-api';
import Header from 'UI/components/header/header.component';

function LogOut({ setLoading }) {
  const history = useHistory();
  const location = useLocation();

  function loadRedirectTarget() {
    const query = new URLSearchParams(location.search);
    if (!localStorage.getItem(REDIRECT_LOGOUT_KEY)) {
      localStorage.setItem(REDIRECT_LOGOUT_KEY, query.get('next') || '');
    }
  }

  async function redirectIfTarget() {
    await Auth.currentAuthenticatedUser().catch(() => {
      // Only redirects to "next" parameter url or back to
      // sso home if the user has already been logged out
      const redirectLink = localStorage.getItem(REDIRECT_LOGOUT_KEY);
      if (redirectLink && isLoggiDomain(redirectLink)) {
        localStorage.removeItem(REDIRECT_LOGOUT_KEY);
        window.location.replace(redirectLink);
      } else {
        history.push(ROUTES.home);
      }
    });
  }

  async function handleLogout() {
    try {
      setLoading(true);
      await Auth.signOut();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error signing out: ', error);
      Sentry.captureMessage(error);
    } finally {
      setLoading(false);
      redirectIfTarget();
    }
  }

  useEffect(() => {
    loadRedirectTarget();
    handleLogout();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Header />
    </Box>
  );
}

LogOut.propTypes = {
  setLoading: PropTypes.func.isRequired
};

export default LogOut;
