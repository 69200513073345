export const ERROR_MESSAGES_PT = {
  LOGGI_USER_MUST_USE_GOOGLE:
    'Usuários com e-mail Loggi precisam logar via conta social Google. Por ' +
    'favor clique em "Continuar com o Google".',
  UNAUTHORIZED: 'E-mail ou senha inválidos.',
  USER_DOES_NOT_EXIST: 'E-mail ou senha inválidos.',
  FORBIDDEN:
    'Não foi possível fazer login. Entre em contato com a nossa Central de Ajuda.',
  UNKNOWN: 'Não foi possível entrar na sua conta. Tente de novo.',
  MULTIPLE_LOGGI_ENVIOS_COMPANY_ACCESS:
    'Você precisa de um convite da sua empresa para utilizar o serviço de entregas locais.',
  MULTIPLE_PERSONAL_COMPANY_ACCESS:
    'Você precisa de um convite para utilizar o serviço de entregas locais.',
  NEEDS_INVITE_OR_SIGN_UP:
    'Opa, você precisa receber um convite ou se cadastrar para acessar esse serviço.',
  UNREACHABLE: 'Não foi possível entrar na sua conta. Tente de novo.',
  NOT_AUTHORIZED_INVALID_CREDENTIALS: 'E-mail ou senha errados.',
  NOT_AUTHORIZED_SHOULD_SIGN_IN_WITH_GOOGLE_ACCOUNT:
    'Você já se cadastrou na Loggi com uma conta Google. Toque em Continuar com Google para entrar.',
  USER_NOT_REGISTERED_AS_GOOGLE_FEDERATED_LOGIN:
    'Você já se cadastrou na Loggi com uma conta Google. Toque em Continuar com Google para entrar.',
  INVALID_REDIRECT: 'URL Inválida',
  GENERIC: 'Não foi possível entrar na sua conta. Tente de novo.',
  SIGN_UP_GOOGLE_USER_LINK_REDIRECT:
    'Sua conta agora está associada à sua conta Google. Por favor, escolha a opção “logar com Google” novamente.',
  CORPORATE_USER_MUST_EXIST_FOR_AUTOMATIC_USER_MIGRATION:
    'Você ainda não tem uma conta na Loggi. Acesse "Criar conta" e cadastre-se.',
  CORPORATE_LOGGI_USER_MUST_EXIST_FOR_AUTOMATIC_USER_MIGRATION:
    'Você ainda não tem uma conta na Loggi. Acesse "Criar conta" e cadastre-se.',
  LW_EMAIL_ALREADY_EXISTS: 'E-mail já cadastrado.',
  PASSWORD_ATTEMPTS_EXCEEDED:
    'Você excedeu o limite de tentativas de login. Por favor, tente novamente mais tarde ou clique em recuperar senha',
  ACCOUNT_IS_DISABLED:
    'Sua conta foi desabilitada. Por favor, entre em contato com a nossa Central de Ajuda.',
  ACCOUNT_IS_DISABLED_COGNITO:
    'Sua conta foi desabilitada. Por favor, entre em contato com a nossa Central de Ajuda.',
  PASSWORD_MUST_BE_RESET:
    'Seu acesso depende de convite de alguém da sua empresa.' +
    ' Certifique-se de fazer a requisição no canal adequado',
  API_USER_MIGRATION:
    'Há um problema com a sua conta. Por favor, entre em contato com a nossa Central de Ajuda.',
  PASSWORD_RESET_REQUIRED:
    'Clique em "Esqueceu sua senha?" para redefinir a sua senha.'
};

export const COGNITO_ERROR_MESSAGES = {
  CORPORATE_USER_MUST_EXIST_FOR_AUTOMATIC_USER_MIGRATION:
    'PreSignUp+failed+with+error+Corporate+user+must+exist+for+automatic+user+migration',
  CORPORATE_LOGGI_USER_MUST_EXIST_FOR_AUTOMATIC_USER_MIGRATION:
    'PreSignUp+failed+with+error+Corporate+user+must+exist+for+automatic+loggiuser+migration',
  SIGN_UP_GOOGLE_USER_LINK_REDIRECT:
    'PreSignUp+failed+with+error+User+account+has+been+linked+to+corresponding+Google+federated+account',
  LW_EMAIL_ALREADY_EXISTS:
    'PreSignUp+failed+with+error+There+is+already+a+LoggiUser+with+this+email',
  USER_DOES_NOT_EXIST: 'UserMigration failed with error User does not exist..',
  NOT_AUTHORIZED_INVALID_CREDENTIALS: 'Incorrect username or password.',
  NOT_AUTHORIZED_SHOULD_SIGN_IN_WITH_GOOGLE_ACCOUNT:
    'User is not authorized to get auth details.',
  PASSWORD_MUST_BE_RESET:
    'Temporary password has expired and must be reset by an administrator',
  PASSWORD_ATTEMPTS_EXCEEDED: 'Password attempts exceeded',
  ACCOUNT_IS_DISABLED: 'User is disabled',
  ACCOUNT_IS_DISABLED_COGNITO: 'User+is+not+enabled',
  USER_NOT_REGISTERED_AS_GOOGLE_FEDERATED_LOGIN:
    "PreAuthentication failed with error The user state is invalid to log in, it's expected " +
    'EXTERNAL_PROVIDER_STATE to @loggi.com account.',
  API_USER_MIGRATION:
    'UserMigration failed with error APIMigrationError - Failed with unknown error..',
  PASSWORD_RESET_REQUIRED: 'Password reset required for the user'
};

export const COGNITO_LOGIN_FLOW_TYPE = {
  GOOGLE_LOGIN: 'USER_PASSWORD_AUTH',
  EMAIL_PASSWORD_LOGIN: 'USER_SRP_AUTH'
};

export const USER_WITH_INCOMPLETE_SIGNUP = 'USER_WITH_INCOMPLETE_SIGNUP';
