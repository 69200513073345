import LineInputBase from '../../../../components/line-inputs/line-input-base.component';
import PasswordInput from '../../../../components/line-inputs/password-input/password-input.component';
import CodeConfirmationInput from '../../../../components/line-inputs/confirmation-code-input/confirmation-code-input.component';
import { INPUT_TYPES } from '../../../../constants/input-types';
import { withTransition } from './step-input.styled';

export const INPUT_PROPS_BY_TYPE = {
  [INPUT_TYPES.text]: { type: 'text' },
  [INPUT_TYPES.email]: { type: 'email', inputMode: 'email' },
  [INPUT_TYPES.password]: { type: 'password', inputMode: 'password' }
};

export const INPUT_BY_TYPE = {
  [INPUT_TYPES.text]: withTransition(LineInputBase),
  [INPUT_TYPES.email]: withTransition(LineInputBase),
  [INPUT_TYPES.password]: withTransition(PasswordInput),
  [INPUT_TYPES.confirmationCode]: withTransition(CodeConfirmationInput)
};
