export const KNOWN_ERRORS = [
  'UserNotFoundException',
  'LimitExceededException',
  'NetworkError',
  'DefaultException',
  'CodeMismatchException',
  'ExpiredCodeException',
  'NotAuthorizedException',
  'InvalidUserException',
  'InvalidPasswordException',
  'APIMigrationError'
];

export const THROWBACK_ERRORS = [
  'default',
  'UserNotFoundException',
  'LimitExceededException',
  'NotAuthorizedException',
  'InvalidUserException',
  'InvalidPasswordException',
  'APIMigrationError'
];

export const ERROR_MESSAGES_PT = {
  default: 'Tem algo errado com o seu e-mail. Tente de novo.',
  UserNotFoundException:
    'Não encontramos seu e-mail na nossa base. Certifique-se de que você esteja usando o e-mail cadastrado.',
  LimitExceededException:
    'Você solicitou um código muitas vezes. Tente novamente mais tarde.',
  NetworkError: 'Não foi possível enviar o código. Tente de novo.',
  DefaultException: 'Não foi possível alterar sua senha.',
  CodeMismatchException: 'Código errado. Tente outra vez.',
  ExpiredCodeException: 'Código expirado. Faça uma nova solicitação.',
  InvalidUserException:
    'Há um problema com o cadastro de sua conta. Por favor, entre em contato com a nossa Central de Ajuda.',
  InvalidPasswordException:
    'A nova senha precisa ser diferente de outras utilizadas anteriormente e conter ao menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial.',
  NotAuthorizedException:
    'Você entrou previamente com login social (Google ou Apple). Toque em "Continuar com Google" ou "Continuar com Apple" para entrar. Caso contrário, por favor entre em contato com a nossa Central de Ajuda.',
  APIMigrationError:
    'Há um problema com a sua conta. Por favor, entre em contato com a nossa Central de Ajuda.'
};
