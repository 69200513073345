import merge from 'lodash/merge';
import mar, { colors } from '@loggi/mar';
import { blue } from './colors';

export default merge(mar.palette, {
  primary: {
    light: colors.blue[100],
    main: blue[500],
    dark: colors.blue[700],
    contrastText: colors.root[0]
  },
  secondary: {
    light: colors.blue[50],
    main: blue[500],
    dark: colors.blue[700],
    contrastText: colors.blue[100]
  },
  background: {
    default: colors.root[0]
  }
});
