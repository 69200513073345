/* eslint-disable no-void */
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { colors } from '@loggi/mar';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import AppleSignInButton from 'UI/components/sign-in/apple-sign-in-button.component';
import GoogleSignInButton from 'UI/components/sign-in/google-sign-in-button.component';
import { Auth } from 'aws-amplify';
import { StepFormWrapper } from 'components';
import getFeatureSwitchValue from 'configuration/get-feature-switch-value';
import SWITCHES from 'configuration/switches';
import { ERROR_MESSAGES_PT } from 'constants/error-messages';
import useToastNotification from 'hooks/toast-notification/toast.hook';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ValidatorBuilder from '../../../infra/validators/validator-builder';
import { useUserRecords } from '../../components/contexts/user-records/user-records.context';

export const SIGN_UP_BUTTON_ID = 'sign-up-button-id';
export const FORGOT_PASSWORD_BUTTON_ID = 'forgot-password-button-id';
export const USER_SIGNIN_LOADING_ID = 'user-signin-loading';

export const PROVIDERS = {
  GOOGLE: 'Google',
  APPLE: 'SignInWithApple'
};

const SignIn = ({ onForgotPassword, onSubmit, setLoading }) => {
  const showToastNotification = useToastNotification();
  const [isForgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const { userRecords, setUserRecords } = useUserRecords();

  const fields = {
    email: {
      label: 'Digite seu e-mail',
      placeholder: 'Digite seu e-mail',
      type: 'email',
      value: userRecords.email,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .emailPattern('E-mail inválido. Tente outro e-mail.')
        .build()
    },
    password: {
      label: 'Digite sua senha',
      placeholder: 'Digite sua senha',
      type: 'password',
      validator: new ValidatorBuilder()
        .nonEmptyString('Você precisa digitar sua senha.')
        .build()
    }
  };

  const handleStepChange = (step, { email }) => {
    if (email) setUserRecords({ ...userRecords, email });
    if (step === 1) {
      setForgotPasswordVisible(true);
      return;
    }
    setForgotPasswordVisible(false);
  };

  const handleSubmit = async userCredentials => {
    const blockLoggers = await getFeatureSwitchValue(
      SWITCHES.forceLoggersGoogleSignin
    );

    if (blockLoggers && userCredentials.email.includes('@loggi.com')) {
      showToastNotification(
        ERROR_MESSAGES_PT.LOGGI_USER_MUST_USE_GOOGLE,
        'error'
      );
      return;
    }
    setLoading(true);
    const result = await onSubmit(userCredentials);
    if (!result) setLoading(false);
  };

  const handleFederatedSignIn = provider => {
    setLoading(true);
    return Auth.federatedSignIn({ provider });
  };

  const handleCreateAccountButtonClick = async () => {
    const useSingleSignUpURL = await getFeatureSwitchValue(
      SWITCHES.useSingleSignUpURL
    );

    window.location.href = useSingleSignUpURL
      ? process.env.REACT_APP_SINGLE_SIGNUP_REDIRECT
      : process.env.REACT_APP_SIGN_UP_REDIRECT;
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <Box mt={1}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            my={3}
          >
            <StepFormWrapper
              fields={fields}
              onSubmit={handleSubmit}
              isInputLabelVisible={false}
              onStepChange={handleStepChange}
              buttonLabel="Continuar"
            />
            {isForgotPasswordVisible && (
              <Button
                variant="outlined"
                color="secondary"
                data-testid={FORGOT_PASSWORD_BUTTON_ID}
                style={{ marginTop: '16px' }}
                onClick={() => onForgotPassword(userRecords.email)}
              >
                Esqueceu sua senha?
              </Button>
            )}
          </Box>
        </Box>
        <Box
          fontWeight="fontWeightBold"
          color={colors.smoke[700]}
          alignSelf="center"
        >
          <Typography variant="body2">Escolha</Typography>
        </Box>
        <Box my={2}>
          <GoogleSignInButton
            onClick={() =>
              handleFederatedSignIn(CognitoHostedUIIdentityProvider.Google)
            }
          />
        </Box>
        <Box my={2}>
          <AppleSignInButton
            onClick={() =>
              handleFederatedSignIn(CognitoHostedUIIdentityProvider.Apple)
            }
          />
        </Box>
        <Box mt={3} mb={6}>
          <Divider />
          <Box my={3} textAlign="center">
            <Box
              fontWeight="fontWeightBold"
              color={colors.smoke[700]}
              alignSelf="center"
            >
              <Typography variant="body2">
                Não tem uma conta na Loggi?
              </Typography>
            </Box>
          </Box>
          <Button
            onClick={() => void handleCreateAccountButtonClick()}
            variant="outlined"
            color="secondary"
            fullWidth
            data-testid={SIGN_UP_BUTTON_ID}
          >
            Crie sua conta
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SignIn.propTypes = {
  onForgotPassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
};

export default SignIn;
