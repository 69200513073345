import { useAmplifyAuth } from '@loggi/authentication-lib';
import useToastNotification from 'hooks/toast-notification/toast.hook';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ForgotPasswordPage } from 'UI/pages/forgot-password/forgot-password.page';
import { FORGOT_PASSWORD_EMAIL_KEY } from '../../constants/auth';
import {
  ERROR_MESSAGES_PT,
  KNOWN_ERRORS,
  THROWBACK_ERRORS
} from '../../constants/forgot-password';

function ForgotPassword() {
  const history = useHistory();
  const {
    forgotPassword,
    sendPasswordRecovery,
    state: { error: authError }
  } = useAmplifyAuth();
  const showToastNotification = useToastNotification();

  const email = useMemo(() => {
    const query = new URLSearchParams(history.location.search.substring(1));
    return query.get(FORGOT_PASSWORD_EMAIL_KEY);
  }, [history.location.search]);

  const handleSubmit = ({ confirmationCode, newPassword }) =>
    sendPasswordRecovery(email, confirmationCode, newPassword);

  const handleRequestConfirmationCode = async () => {
    await forgotPassword(email);
  };

  useEffect(() => {
    if (authError) {
      let errorMessage = KNOWN_ERRORS.find(msg =>
        authError.message?.message?.includes(msg)
      );

      if (!errorMessage) {
        errorMessage = KNOWN_ERRORS.includes(authError?.message?.code)
          ? authError.message.code
          : 'default';
      }

      showToastNotification(ERROR_MESSAGES_PT[errorMessage], 'error');
      if (THROWBACK_ERRORS.includes(errorMessage)) {
        history.goBack();
      }
    }
  }, [authError, showToastNotification, history]);

  return (
    <ForgotPasswordPage
      onGoBack={history.goBack}
      onSubmit={handleSubmit}
      onRequestConfirmationCode={handleRequestConfirmationCode}
    />
  );
}

export default ForgotPassword;
