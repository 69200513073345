import ForgotPassword from 'containers/forgot-password/forgot-password';
import Home from 'containers/home/home';
import LogOut from 'containers/log-out/log-out';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import CompleteSignUp from 'UI/pages/complete-sign-up/complete-sign-up.page';
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as ROUTES from './routes';

const AppRouter = ({ setLoading }) => {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  return (
    <Switch>
      <Route path={ROUTES.logout}>
        <LogOut setLoading={setLoading} />
      </Route>
      <Route path={ROUTES.forgotPassword}>
        <ForgotPassword />
      </Route>
      {authenticatedUser && (
        <Route path={ROUTES.completeSignUp}>
          <CompleteSignUp />
        </Route>
      )}
      <Route path={ROUTES.home}>
        <Home setLoading={setLoading} />
      </Route>
    </Switch>
  );
};

AppRouter.propTypes = {
  setLoading: PropTypes.func.isRequired
};

export default AppRouter;
