import {
  Box,
  Card,
  CircularProgress,
  Modal,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const LoadingModal = ({ isLoading, loadingMessage }) => {
  return (
    <Modal open={isLoading}>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="100vh"
        bgcolor="white"
      >
        <Card>
          <Box display="flex" alignItems="center" px={3} py={2}>
            <Box mx={2}>
              <CircularProgress data-testid="loading-modal-circular-progress" />
            </Box>
            {loadingMessage && (
              <Box mx={2}>
                <Typography variant="subtitle2">
                  <strong>{loadingMessage}</strong>
                </Typography>
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

LoadingModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadingMessage: PropTypes.string
};

LoadingModal.defaultProps = {
  loadingMessage: ''
};

export default LoadingModal;
