export const blue = {
  200: '#CCF1FF',
  300: '#00BAFF',
  400: '#0095FF',
  500: '#006AFF',
  600: '#004099',
  700: '#0E3163'
};

export const gray = {
  200: '#F2F2F2',
  300: '#333333',
  400: '#6F7680'
};

export const root = {
  0: '#000000',
  200: '#D8DDEB'
};

export const red = {
  100: '#CC0014',
  500: '#C10404'
};

export const yellow = {
  500: '#FFDD00'
};

export const gradients = {
  beyond: `linear-gradient(286.66deg, ${blue[500]}, ${blue[500]})`
};

const colors = { blue, gradients, gray, root };
export default colors;
