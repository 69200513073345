import React from 'react';
import { useHistory } from 'react-router-dom';
import { colors } from '@loggi/mar';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { FORMS_VARIANTS } from 'constants/forms-variants';
import { ReactComponent as SeatedPersonIllustration } from '../../../assets/seated-person-illustration.svg';

const CompleteSignUp = () => {
  const history = useHistory();
  const { boxShadow } = FORMS_VARIANTS.primary;

  const handleCompleteSignUpButton = () => {
    // TODO: This function will be implemented to redirect the authenticated user to the new target,
    // if they need to complete their registration, after receiving the response from the
    // UserMigrationSessionAPI indicating the need for completion.
  };

  return (
    <Box>
      <Box pt={5} pl={3} pb={2}>
        <IconButton
          data-testid="back-button"
          color="inherit"
          fontSize="small"
          style={{ padding: '0px' }}
          onClick={history.goBack}
        >
          <ArrowBackIos color="inherit" />
        </IconButton>
      </Box>
      <Divider style={{ backgroundColor: colors.smoke[50] }} />
      <Box p={3}>
        <Box>
          <Typography variant="h5">
            Você já começou um cadastro de conta antes
          </Typography>
        </Box>
        <Box pt={4}>
          <Typography variant="body1">
            Complete as informações que faltam antes de fazer o login
          </Typography>
        </Box>
        <Box
          py={4}
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <SeatedPersonIllustration />
        </Box>
        <Box boxShadow={boxShadow} borderRadius="25px">
          <Button
            color="primary"
            fullWidth
            type="button"
            height={48}
            variant="contained"
            onClick={handleCompleteSignUpButton}
          >
            Completar cadastro
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CompleteSignUp;
