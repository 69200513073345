import { blue, root } from 'theme/colors';

export const COLOR_VARIANTS = { PRIMARY: 'primary', WHITE: 'white' };

export const HEADER_VARIANTS = {
  [COLOR_VARIANTS.PRIMARY]: {
    color: blue[500],
    backButtonLabelColor: root[0],
    backgroundColor: root[900],
    borderBottomColor: 'rgb(204, 204, 204, 0.6)' // gray[500] with 0.6 opacity
  },
  [COLOR_VARIANTS.WHITE]: {
    color: 'white',
    backButtonLabelColor: 'white'
  }
};

export const FORMS_VARIANTS = {
  [COLOR_VARIANTS.PRIMARY]: {
    background: blue[500],
    boxShadow: '0px 4px 8px 0px #00000040',
    color: 'white'
  },
  [COLOR_VARIANTS.WHITE]: {
    background: 'white',
    boxShadow: '0px 10px 20px rgba(0, 106, 255, 0.33)',
    color: blue[500]
  }
};
