/* eslint-disable no-unused-vars */
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { FORGOT_PASSWORD_EMAIL_KEY } from 'constants/auth';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { forgotPassword as forgotPasswordRoute } from 'routes/routes';
import SignInUI from '../../UI/pages/sign-in/sign-in.page';

const SignIn = ({ setLoading }) => {
  const history = useHistory();
  const { signIn } = useAmplifyAuth();

  const handleSignIn = ({ email, password }) => {
    return signIn(email, password);
  };

  const handleOnForgotPassword = async email => {
    history.push({
      pathname: forgotPasswordRoute,
      search: `?${FORGOT_PASSWORD_EMAIL_KEY}=${encodeURIComponent(email)}`
    });
  };

  return (
    <SignInUI
      onForgotPassword={handleOnForgotPassword}
      onSubmit={handleSignIn}
      setLoading={setLoading}
    />
  );
};

SignIn.propTypes = {
  setLoading: PropTypes.func.isRequired
};

export default SignIn;
