import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { PrimaryInput, WhiteInput } from './line-input.styled';

const INPUTS_BY_VARIANT = { primary: PrimaryInput, white: WhiteInput };

const LineInputBase = ({
  className,
  endAdornment,
  error,
  inputProps,
  inputRef,
  onChange,
  onEnterKeyDown,
  placeholder,
  style,
  type,
  value,
  variant
}) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter' && onEnterKeyDown) onEnterKeyDown();
  };

  const InputComponent = get(
    INPUTS_BY_VARIANT,
    variant,
    INPUTS_BY_VARIANT.primary
  );

  return (
    <InputComponent
      autoFocus
      className={className}
      error={Boolean(error)}
      helperText={error}
      InputProps={{ endAdornment, inputProps }}
      inputRef={inputRef}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      style={style}
      type={type}
      value={value}
    />
  );
};

LineInputBase.propTypes = {
  className: PropTypes.string,
  endAdornment: PropTypes.node,
  error: PropTypes.string,
  inputProps: PropTypes.shape({}),
  inputRef: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  onEnterKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.shape({}),
  type: PropTypes.oneOf(['email', 'number', 'password', 'text']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.oneOf(Object.keys(INPUTS_BY_VARIANT))
};

LineInputBase.defaultProps = {
  className: '',
  endAdornment: null,
  error: '',
  inputProps: {},
  inputRef: {},
  onEnterKeyDown: null,
  placeholder: '',
  style: {},
  type: 'text',
  value: undefined,
  variant: 'primary'
};

export default LineInputBase;
