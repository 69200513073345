import firebase from 'firebase/app';

// These are some variables that should be shared across multiple calls to this
// hook, introducing a Singleton pattern here
let remoteConfigActivatePromise = null;
let configs = null;

const preloadFirebaseConfigs = () => {
  const remoteConfig = firebase.remoteConfig();

  remoteConfigActivatePromise = remoteConfig.fetchAndActivate();
  return remoteConfigActivatePromise.then(() => {
    // Uses the getAll method to cache all the current remote configs
    configs = remoteConfig.getAll();
  });
};

const getRemoteConfig = remoteConfigParam => {
  if (!remoteConfigActivatePromise) {
    remoteConfigActivatePromise = preloadFirebaseConfigs();
  }

  return remoteConfigActivatePromise.then(() => {
    return configs[remoteConfigParam];
  });
};

export default getRemoteConfig;
