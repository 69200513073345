import { AmplifyAuthProvider } from '@loggi/authentication-lib';
import { ThemeProvider } from '@material-ui/core/styles';
import 'configuration/firebase-init';
import 'configuration/polyfills';
import 'configuration/tag-manager-init';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserRecordsContextProvider } from 'UI/components/contexts';
import '../sentry/sentry-init';
import App from './App';
import './index.css';
import theme from './theme';

ReactDOM.render(
  <UserRecordsContextProvider>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={7000}
        maxSnack={3}
      >
        <AmplifyAuthProvider>
          <Router>
            <App />
          </Router>
        </AmplifyAuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </UserRecordsContextProvider>,
  document.getElementById('root')
);
