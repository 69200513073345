import { REDIRECT_STORAGE_KEY } from 'constants/string-keys';
import { MIGRATION_SESSION } from '../constants/api-paths';
import {
  ERROR_MESSAGES_PT,
  USER_WITH_INCOMPLETE_SIGNUP
} from '../constants/error-messages';

export function isLoggiDomain(url) {
  const match = /^(https|http)?:\/\/([a-z\d-]+\.)*loggi.com(?=[/:]|$)/;
  const re = new RegExp(match, 'i');
  return re.test(url);
}

export function getRedirectUrlFromNext(nextParam) {
  const defaultUrl = process.env.REACT_APP_LOGGI_URL;
  const defaultApp = process.env.REACT_APP_DEFAULT_CORP_APP_REDIRECT;
  let redirectUrl = `https://${defaultUrl + defaultApp}`;

  if (isLoggiDomain(nextParam)) redirectUrl = nextParam;
  else if (nextParam?.[0] === '/')
    redirectUrl = `https://${defaultUrl + nextParam}`;

  return redirectUrl;
}

export const redirectToEndpoint = () => {
  const nextParam = localStorage.getItem(REDIRECT_STORAGE_KEY);
  localStorage.removeItem(REDIRECT_STORAGE_KEY);
  const redirectLink = getRedirectUrlFromNext(nextParam);
  window.location.replace(redirectLink);
};

export const authenticateWebAndRedirect = async requestOptions => {
  // authenticates on loggi web to obtain the session id, then redirects back

  return fetch(
    // eslint-disable-next-line
    process.env.REACT_APP_SESSION_MIGRATION_BASE_URL + MIGRATION_SESSION,
    requestOptions
  )
    .then(async response => {
      if (response.ok) {
        redirectToEndpoint();
      } else {
        let errorMessage = response.status;

        if (String(response.status) === '400') {
          errorMessage = await response.json().then(res => res.code);
        }

        throw new Error(errorMessage);
      }
    })
    .catch(error => {
      const hasIncompleteSignup = error.message === USER_WITH_INCOMPLETE_SIGNUP;

      if (hasIncompleteSignup) {
        throw error;
      }

      const needsInviteOrSignUpCases = [
        'USER_WITHOUT_LOGGI_ENVIOS_COMPANIES',
        'USER_WITHOUT_PERSONAL_COMPANIES',
        'INVALID_LOGGI_ENVIOS_ROLE',
        'INVALID_PERSONAL_CUSTOMER_ROLE',
        'USER_WITHOUT_COMPANY_ACCESS'
      ];
      const needsInviteOrSignUp = needsInviteOrSignUpCases.includes(
        error.message
      );

      const errorCode =
        ERROR_MESSAGES_PT[error.message] || needsInviteOrSignUp
          ? '400'
          : error.message;

      switch (errorCode) {
        case '400':
          throw new Error(
            needsInviteOrSignUp
              ? ERROR_MESSAGES_PT.NEEDS_INVITE_OR_SIGN_UP
              : ERROR_MESSAGES_PT[error.message]
          );
        case '401':
          throw new Error(ERROR_MESSAGES_PT.UNAUTHORIZED);
        case '403':
          throw new Error(ERROR_MESSAGES_PT.FORBIDDEN);
        case 'Failed to fetch':
          throw new Error(ERROR_MESSAGES_PT.UNREACHABLE);
        default:
          // eslint-disable-next-line no-console
          console.log('UNKOWN error!');
          throw new Error(`${ERROR_MESSAGES_PT.UNKNOWN}: ${error}`);
      }
    });
};

export default authenticateWebAndRedirect;
