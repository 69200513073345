import marTypography from '@loggi/mar/src/typography';
import { pxToRem } from '@loggi/mar/src/utils';

const typography = {
  ...marTypography,
  fontFamily: 'Montserrat, sans-serif',
  body3: {
    fontSize: pxToRem(13),
    lineHeight: 1.5,
    fontWeight: 400
  }
};

export default typography;
