import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepFormWrapper } from 'UI/components';
import { INPUT_TYPES } from '../../../../constants/input-types';
import ValidatorBuilder from '../../../../infra/validators/validator-builder';

export const RESEND_CONFIRMATION_CODE_BUTTON_TEST_ID =
  'resend-confirmation-code-button';

export function PasswordResetForm({
  onSubmit,
  onRequestResendConfirmationCode
}) {
  const { t } = useTranslation('ui');
  const [step, setStep] = useState(0);
  const fields = {
    confirmationCode: {
      label: 'Insira o código',
      type: INPUT_TYPES.confirmationCode,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .codeLengthConfirmation('Código muito curto. Tente outro código.')
        .build()
    },
    newPassword: {
      label: t('Crie sua senha'),
      type: INPUT_TYPES.password,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .passwordLength('Senha muito curta. Tente outra senha.')
        .build()
    }
  };

  const handleStepChange = currStep => {
    if (step === currStep) return;
    setStep(currStep);
  };

  return (
    <Box pb={2}>
      <StepFormWrapper
        fields={fields}
        onStepChange={handleStepChange}
        onSubmit={onSubmit}
        buttonLabel="Continuar"
      />
      {step === 0 && (
        <Box mt={3}>
          <Button
            color="primary"
            data-testid={RESEND_CONFIRMATION_CODE_BUTTON_TEST_ID}
            fullWidth
            onClick={onRequestResendConfirmationCode}
            variant="outlined"
          >
            Reenviar código
          </Button>
        </Box>
      )}
    </Box>
  );
}

PasswordResetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onRequestResendConfirmationCode: PropTypes.func.isRequired
};
