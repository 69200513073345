import { colors } from '@loggi/mar';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as LoggiBrand } from '../../../assets/loggi-brand.svg';

const Header = ({ onGoBack }) => {
  return (
    <Box
      alignItems="center"
      color={colors.smoke[500]}
      display="flex"
      justifyContent="space-between"
      py={3}
    >
      {onGoBack && (
        <IconButton
          color="inherit"
          fontSize="small"
          style={{ padding: '0px' }}
          onClick={onGoBack}
        >
          <ArrowBackIos color="inherit" />
          <Box color={colors.blue[500]} mx={0.5}>
            <Typography>Voltar</Typography>
          </Box>
        </IconButton>
      )}
      <LoggiBrand />
    </Box>
  );
};

Header.defaultProps = {
  onGoBack: null
};

Header.propTypes = {
  onGoBack: PropTypes.func
};

export default Header;
