import { styled, withStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Alert as MuiAlert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';

const InfoIcon = styled(Info)({ color: 'black' });
const ALERT_BOX_SHADOW = 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px';
const Alert = withStyles({
  standardInfo: {
    backgroundColor: 'white',
    boxShadow: ALERT_BOX_SHADOW,
    color: 'black'
  },
  standardError: {
    boxShadow: ALERT_BOX_SHADOW,
    color: 'black'
  }
})(MuiAlert);

const useToastNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function showToastNotification(message, type = 'info') {
    const handleSnackbarClick = key => () => closeSnackbar(key);

    const options = {
      content: (key, msg) => (
        <Alert
          icon={<InfoIcon />}
          key={key}
          color={type}
          onClose={handleSnackbarClick(key)}
          data-gtm-error={type === 'error' ? msg : undefined}
        >
          {msg}
        </Alert>
      )
    };
    enqueueSnackbar(message, options);
  }

  return useCallback(showToastNotification, [enqueueSnackbar, closeSnackbar]);
};

export default useToastNotification;
