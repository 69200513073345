import merge from 'lodash/merge';
import mar, { colors } from '@loggi/mar';
import { blue } from './colors';

export default merge(mar.overrides, {
  MuiButton: {
    containedPrimary: {
      background: blue[500],
      '&:hover': {
        backgroundColor: blue[600]
      }
    },
    outlinedPrimary: {
      backgroundColor: colors.root[0],
      '&:hover': {
        backgroundColor: colors.smoke[100]
      }
    }
  },
  MuiFormLabel: {
    root: {
      '&$focused': {
        color: blue[500]
      }
    }
  },
  MuiOutlinedInput: {
    root: {
      '&$focused $notchedOutline': {
        borderColor: blue[500]
      }
    }
  }
});
