import { styled, TextField } from '@material-ui/core';
import { blue, gray, red, yellow } from 'theme/colors';

export const PrimaryInput = styled(TextField)({
  '& input': {
    transition: 'all 300ms ease, font-weight 0s, color 0s',
    fontSize: '18px',
    caretColor: blue[500],
    padding: '8px 0px',
    height: '27px'
  },
  '& > input::placeholder': {
    color: gray[600],
    opacity: 1,
    lineHeight: '150%'
  },
  '&::before, & .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: `1px solid ${gray[400]}`
  },
  '&::after, & .MuiInput-underline::after': {
    borderBottom: `1px solid ${blue[500]}`
  },
  '& .MuiInput-underline.Mui-error:after': {
    borderBottom: `1px solid ${red[500]}`
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: red[500],
    fontSize: '14px',
    fontWeight: '700',
    marginTop: '8px'
  }
});

export const WhiteInput = styled(TextField)({
  '& input': {
    color: 'white',
    fontSize: '18px',
    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
      '-webkit-text-fill-color': 'white',
      '-webkit-box-shadow': `0 0 0 30px ${blue[500]} inset`
    }
  },
  '& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:hover:not(.Mui-disabled):after': {
    borderBottom: '1px solid white'
  },
  '& .MuiInput-underline.MuiInput-focused:before, & .MuiInput-underline.MuiInput-focused:after': {
    transform: 'scale(0)'
  },
  '& .MuiFormHelperText-root': {
    fontSize: '14px',
    fontWeight: '700',
    padding: '8px 0 0 0'
  },
  '& .MuiFormHelperText-root.Mui-error': { color: yellow[500] },
  '& .MuiInput-underline.Mui-error:after': {
    borderBottomColor: yellow[500]
  },
  '& > input::placeholder': {
    color: 'white',
    opacity: 1,
    lineHeight: '150%'
  }
});
