import { colors } from '@loggi/mar';
import { Box, Fade, Slide, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Header, Loader, SuccessPage } from 'UI/components';
import { ReactComponent as HighFiveIllustration } from 'assets/high-five.svg';
import { PasswordResetForm } from './password-reset-form/password-reset-form.component';

const SLIDE_TRANSITION_TIMEOUT = 640;
const FADE_TRANSITION_TIMEOUT = 800;
function FullScreenStep({ children }) {
  return (
    <Box display="flex" justifyContent="center" height="100%">
      {children}
    </Box>
  );
}

const PaddedBox = styled(Box)({
  padding: '0px 24px 48px',
  backgroundColor: colors.root[0],
  height: '100vh'
});

export function ForgotPasswordPage({
  onGoBack,
  onSubmit,
  onRequestConfirmationCode
}) {
  const [page, setPage] = useState(0);
  const alreadyRequested = useRef(false);
  const isMountedRef = useRef(true);

  const goPageBack = () =>
    isMountedRef.current && setPage(prev => Math.max(prev - 1, 0));
  const goPageNext = () =>
    isMountedRef.current && setPage(prev => Math.min(prev + 1, 3));

  const handlePasswordResetSubmit = async ({
    confirmationCode,
    newPassword
  }) => {
    goPageNext();
    const payload = { confirmationCode, newPassword };
    try {
      const result = await onSubmit(payload);
      if (result) goPageNext();
      else goPageBack();
    } catch {
      goPageBack();
    }
  };

  const handleRequestResendConfirmationCode = async () => {
    goPageBack();
    await onRequestConfirmationCode();
    goPageNext();
  };

  const requestPasswordRecovery = useCallback(async () => {
    await onRequestConfirmationCode();
    goPageNext();
  }, [onRequestConfirmationCode]);

  useEffect(() => {
    if (!alreadyRequested.current) {
      requestPasswordRecovery();
      alreadyRequested.current = true;
    }
  }, [requestPasswordRecovery]);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  return (
    <Box height="100vh">
      <Fade
        in={page === 0}
        unmountOnExit
        timeout={{ enter: FADE_TRANSITION_TIMEOUT, exit: 0 }}
      >
        <PaddedBox>
          <FullScreenStep>
            <Loader />
          </FullScreenStep>
        </PaddedBox>
      </Fade>
      <Slide
        direction="left"
        in={page === 1}
        mountOnEnter
        timeout={{ enter: SLIDE_TRANSITION_TIMEOUT, exit: 0 }}
        unmountOnExit
      >
        <PaddedBox>
          <Header onGoBack={onGoBack} />
          <PasswordResetForm
            onSubmit={handlePasswordResetSubmit}
            onRequestResendConfirmationCode={
              handleRequestResendConfirmationCode
            }
          />
        </PaddedBox>
      </Slide>
      <Fade
        in={page === 2}
        mountOnEnter
        timeout={{ enter: FADE_TRANSITION_TIMEOUT, exit: 0 }}
        unmountOnExit
      >
        <PaddedBox>
          <FullScreenStep>
            <Loader />
          </FullScreenStep>
        </PaddedBox>
      </Fade>
      <Slide
        direction="left"
        in={page === 3}
        mountOnEnter
        timeout={{ enter: SLIDE_TRANSITION_TIMEOUT, exit: 0 }}
      >
        <PaddedBox>
          <FullScreenStep>
            <SuccessPage
              goToHomeButtonLabel="Voltar para a tela inicial"
              illustration={<HighFiveIllustration />}
              subtitles={[
                'Alteramos a sua senha.',
                'Agora, é só acessar sua conta.'
              ]}
              title="Sucesso!"
            />
          </FullScreenStep>
        </PaddedBox>
      </Slide>
    </Box>
  );
}

FullScreenStep.propTypes = { children: PropTypes.node.isRequired };
ForgotPasswordPage.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestConfirmationCode: PropTypes.func.isRequired
};

export default { ForgotPasswordPage };
