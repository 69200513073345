import { Box, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { home } from 'routes/routes';

const buttonBoxShadow = { boxShadow: '0px 10px 20px rgba(0, 106, 255, 0.33)' };
export const SUCCESS_SCREEN_BUTTON_TEST_ID = 'success-button';

export function SuccessPage({
  illustration,
  goToHomeButtonLabel,
  subtitles,
  title
}) {
  return (
    <Box display="flex" flexDirection="column" pt={3} width="100%">
      <Typography variant="h5">{title}</Typography>
      <Box mt={4}>
        {subtitles.map(subtitle => (
          <Typography key={subtitle} variant="body1">
            {subtitle}
          </Typography>
        ))}
      </Box>
      <Box alignSelf="center" my={7}>
        {illustration}
      </Box>
      <Box>
        <Button
          color="primary"
          component={Link}
          data-testid={SUCCESS_SCREEN_BUTTON_TEST_ID}
          fullWidth
          style={buttonBoxShadow}
          to={home}
          variant="contained"
        >
          {goToHomeButtonLabel}
        </Button>
      </Box>
    </Box>
  );
}

SuccessPage.propTypes = {
  goToHomeButtonLabel: PropTypes.string.isRequired,
  illustration: PropTypes.node.isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired
};
