import { SvgIcon, Typography, Button, Box, styled } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AppleIcon } from '../../../assets/apple-icon.svg';
import { root } from '../../../theme/colors';

const StyledButton = styled(Button)({
  color: root[0],
  border: `1px solid ${root[200]}`
});

export const APPLE_SIGN_IN_BUTTON_ID = 'APPLE_SIGN_IN_BUTTON_ID';

const AppleSignInButton = ({ onClick }) => {
  return (
    <StyledButton
      variant="outlined"
      fullWidth
      data-testid={APPLE_SIGN_IN_BUTTON_ID}
      onClick={onClick}
    >
      <SvgIcon component={AppleIcon} />
      <Box marginLeft="14px">
        <Typography variant="button">Continuar com a Apple</Typography>
      </Box>
    </StyledButton>
  );
};

AppleSignInButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default AppleSignInButton;
