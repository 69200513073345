import { styled } from '@material-ui/core';
import { blue } from 'theme/colors';

export const withTransition = Component =>
  styled(Component)({
    '&.--display': {
      '& input': {
        cursor: 'pointer',
        fontSize: '14px',
        color: blue[500],
        fontWeight: 'bold',
        padding: '0px',
        height: '21px'
      },
      '& input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 30px white inset !important',
        '-webkit-text-fill-color': `${blue[500]} !important`
      },
      '& .MuiInput-underline::before, & .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: 'transparent'
      },
      '&::after': { borderBottomColor: 'transparent' }
    }
  });

export default {};
