import ConfirmationCodeLengthValidator from './confirmation-code-length/confirmation-code-length.validator';
import EmailValidator from './email/email.validator';
import NonEmptyStringValidator from './non-empty-string/non-empty-string.validator';
import PasswordLengthValidator from './password-length/password-length.validator';

export const DEFAULT_ERROR_MESSAGES_PT = {
  EMAIL_PATTERN: 'E-mail inválido. Tente outro e-mail.',
  NON_EMPTY_STRING: 'Esse campo é obrigatório.',
  PASSWORD_LENGTH: 'Senha muito curta. Tente outra senha.',
  CODE_LENGTH_CONFIRMATION: 'Código muito curto. Tente outro código.'
};

export default class ValidatorBuilder {
  constructor() {
    this._selectedValidators = [];
  }

  build() {
    return value => {
      const errorKey = this._selectedValidators.find(fieldValidator => {
        return !fieldValidator.validate(value);
      });

      return {
        errorMessage: errorKey?.errorMessage ?? null,
        isValid: !errorKey
      };
    };
  }

  emailPattern(errorMessage = DEFAULT_ERROR_MESSAGES_PT.EMAIL_PATTERN) {
    this._addValidator(EmailValidator.validate, errorMessage);
    return this;
  }

  nonEmptyString(errorMessage = DEFAULT_ERROR_MESSAGES_PT.NON_EMPTY_STRING) {
    this._addValidator(NonEmptyStringValidator.validate, errorMessage);
    return this;
  }

  passwordLength(errorMessage = DEFAULT_ERROR_MESSAGES_PT.PASSWORD_LENGTH) {
    this._addValidator(PasswordLengthValidator.validate, errorMessage);
    return this;
  }

  codeLengthConfirmation(
    errorMessage = DEFAULT_ERROR_MESSAGES_PT.CODE_LENGTH_CONFIRMATION
  ) {
    this._addValidator(ConfirmationCodeLengthValidator.validate, errorMessage);
    return this;
  }

  _addValidator(validate, errorMessage) {
    this._selectedValidators.push({ validate, errorMessage });
  }
}
