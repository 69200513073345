import mar from '@loggi/mar';
import { createMuiTheme } from '@material-ui/core';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const theme = {
  ...mar,
  overrides,
  palette,
  typography
};

export default createMuiTheme(theme);
