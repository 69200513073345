import { Box, CssBaseline } from '@material-ui/core';
import Amplify from 'aws-amplify';
import { LoadingModal } from 'components';
import React, { useState } from 'react';
import AppRouter from 'routes/app-router';
import './App.css';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

function App() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <CssBaseline />
      <Box height="100%">
        <LoadingModal isLoading={loading} loadingMessage="Aguarde um momento" />
        <AppRouter loading={loading} setLoading={setLoading} />
      </Box>
    </>
  );
}
export default App;
