import { Box, styled, Typography } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { blue } from 'theme/colors';
import { INPUT_TYPES } from '../../../../constants/input-types';
import { INPUT_BY_TYPE, INPUT_PROPS_BY_TYPE } from './step.input.constants';

const InputLabel = styled(Typography)(({ theme }) => ({
  transition: 'font-size 400ms',
  willChange: 'font-size',
  fontSize: '22px',
  '&.--display': {
    fontSize: '14px',
    display: 'flex'
  },
  '&, &.--display': {
    color: theme
  }
}));

const StepInputBox = styled(Box)(({ variant }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '16px',
  '&.--display': {
    cursor: 'pointer',
    '& input': {
      color: variant === 'primary' ? blue[500] : 'white'
    }
  }
}));

export function StepInput({
  error,
  isActive,
  isDisplayVisible,
  isLabelVisible,
  isLoading,
  label,
  name,
  onDisplayClick,
  onEnterKeyDown,
  onInputChange,
  placeholder,
  type,
  value,
  variant
}) {
  const inputRef = useRef();

  const displayClass = useMemo(() => {
    if (!isActive && isDisplayVisible) return '--display';
    return '';
  }, [isActive, isDisplayVisible]);

  function handleChange({ target }) {
    const { value: val } = target;
    return onInputChange(val);
  }

  function handleFocus() {
    if (displayClass) {
      onDisplayClick();
      inputRef.current?.focus();
    }
  }

  const inputProps = get(
    INPUT_PROPS_BY_TYPE,
    type,
    INPUT_PROPS_BY_TYPE[INPUT_TYPES.text]
  );

  const InputComponent = get(
    INPUT_BY_TYPE,
    type,
    INPUT_BY_TYPE[INPUT_TYPES.text]
  );

  if (isActive || isDisplayVisible) {
    return (
      <StepInputBox
        className={displayClass}
        onClick={handleFocus}
        variant={variant}
      >
        {(!isActive || isLabelVisible) && (
          <Box>
            <InputLabel
              className={displayClass}
              color="textPrimary"
              htmlFor={name}
              variant="h6"
              data-testid={`${name}-step-input-label`}
              theme={variant}
            >
              {label}
            </InputLabel>
          </Box>
        )}
        <InputComponent
          className={displayClass}
          error={error}
          inputProps={{ ...inputProps, name, readOnly: !isActive }}
          inputRef={inputRef}
          isActive={isActive}
          isLoading={isLoading}
          onChange={handleChange}
          onEnterKeyDown={onEnterKeyDown}
          placeholder={placeholder}
          type={inputProps.type}
          value={value}
          variant={variant}
        />
      </StepInputBox>
    );
  }
  return null;
}

export default StepInput;

StepInput.defaultProps = {
  error: '',
  isDisplayVisible: true,
  isLabelVisible: true,
  isLoading: false,
  onEnterKeyDown: null,
  placeholder: '',
  type: INPUT_TYPES.text,
  value: '',
  variant: 'primary'
};

StepInput.propTypes = {
  error: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isDisplayVisible: PropTypes.bool,
  isLabelVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onDisplayClick: PropTypes.func.isRequired,
  onEnterKeyDown: PropTypes.func,
  onInputChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.string
};
